import React from 'react';
import { useToast } from 'native-base';
import RequestMaker from '../Middleware/ApiCaller';
import { useFocusEffect } from '@react-navigation/native';

function getQuery(payload, { skip = false } = {}) {
    const Toast = useToast();
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [isError, setIsError] = React.useState(false);
  
    const refetch = async (params, options) => {
      try {
        let body = {
          ...payload,
        };
        if (params) body = params;
  
        setIsLoading(true);
        const result = await RequestMaker(body);
        setIsLoading(false);
  
        if (
          result.data.success ||
          (!new Object(result.data).hasOwnProperty("success") &&
            !new Object(result.data).hasOwnProperty("errorMessage"))
        ) {
          if (options?.cache) {
            setData((prevData) => {
              if (!prevData) return result.data;
  
              // Create a new object to avoid mutating the state directly
              const updatedData = { ...prevData };
  
              // Iterate over the keys of the new data
              Object.keys(result.data).forEach((key) => {
                if (Array.isArray(result.data[key])) {
                  // If the key exists in previous data, merge arrays
                  if (updatedData[key]) {
                    updatedData[key] = [
                      ...updatedData[key],
                      ...result.data[key],
                    ];
                  } else {
                    // If it doesn't exist, simply add the new array
                    updatedData[key] = result.data[key];
                  }
                } else {
                  // For non-array data, overwrite or add the new property
                  updatedData[key] = result.data[key];
                }
              });
  
              return updatedData;
            });
          } else {
            setData(result.data);
          }
        } else {
          setIsError(true);
          Toast.show({
            title:
              result.data.errorMessage ||
              "Something went wrong! Please try again later.",
            status: "error",
          });
        }
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
        Toast.show({
          title: e.message || "Something went wrong! Please try again later.",
          status: "error",
        });
      }
    };
  
    useFocusEffect(
      React.useCallback(() => {
        if (!skip) {
          refetch();
        }
      }, [])
    );
  
    return { refetch, data, isLoading, isError };
  }
  
  export default getQuery;
  