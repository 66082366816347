/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useToast } from 'native-base';
import CustomButton from '../../../Components/Button';
import Loader from '../../../Components/Loader';
import IntegrationComponentFactory from './ArchiveTemplates/IntegrationComponentFactory';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import { getProviderData, updateIntegrationData, createIntegrationData, createArchiveSchedule } from '../../../Api/integrations';
import { loadIntegrationsThuunk } from '../../../Redux/integrations';
import useLogEvent from '../../../Middleware/useLogEvent';

export default function ArchiveView({ provider, groupIntegration, from, groupId, isTenant, Tenant }) {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const { attributes } = useSelector((state) => state.integrationData);
  const tenant = useSelector((state) => state.User)?.profile?.tenant || Tenant;
  const Toast = useToast();
  const [integration, setIntegration] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCreatedArchive, setCreatedArchive] = useState(false);
  const logEvent = useLogEvent();


  useEffect(() => {
      if(provider){
        loadProviderData();
      }
  }, [route]);

  const loadProviderData = () => {
    setLoading(true);
    getProviderData(provider, groupId)
    .then(res => {
      const integrations = res?.data?.integrations;
      if (res.data.success && integrations.length > 0) {
          setIntegration(integrations[0]);
      } else {
        setIntegration(null)
      }

      setLoading(false);
    })
  }

  const handleSave = () => {
    if (integration) {
      updateIntegration();
    } else {
      createIntegration();
    }
  };

  const updateIntegration = async () => {
    try{
    const data = {
      provider: provider,
      tenant: tenant || Tenant,
      attributes: attributes,
      integration: integration
    }
    if(groupId && !isTenant){
      data.group = groupId;
    }

    updateIntegrationData(data)
    .then(res => {
      if (res?.data?.success) {        
        Toast.show({
        duration: 3000,
        status: 'success',
        title: provider?.name + ' updated successful',
        });

        dispatch(loadIntegrationsThuunk());

        if (from === 'settings') {
          return;
        }

        setCreatedArchive(true);
      } else if(res?.data?.errorMessage){
        Toast.show({
          status: 'error',
          title: res.data.errorMessage,
        })
      }
      setLoading(false);
    })
  } catch (error) {
    Toast.show({
      status: 'error',
      title: 'An error occurred while updating the archive',
    });
  }
  };

  const createIntegration = async () => {

    try{
    const data = {
      provider: provider,
      tenant: tenant || Tenant,
      attributes: attributes,
    }
    if(groupId && !isTenant){
      data['group'] = groupId;
    }

    createIntegrationData(data)
    .then(result => {
      if (result.data.success) {
        logEvent('archiver_setup')
        Toast.show({
        duration: 3000,
        status: 'success',
        title: 'A default schedule for your archive has been created using a default layout. To modify your archive configuration, look at Archive Layout and Archive Schedules. More information is available in our support section.',
        });
        loadProviderData();
        createSchedule();
      } else if(result?.data?.errorMessage){
        Toast.show({
          status: 'error',
          title: result.data.errorMessage,
        })
      }
    })
  } catch (error) {
    Toast.show({
      status: 'error',
      title: 'An error occurred while creating the archive',
    });
  }
  };

  const createSchedule = async () => {

    setLoading(true);
    const data = {
      provider: provider,
      tenant: tenant || Tenant,
      attributes: attributes,
    }
    if(groupId && !isTenant){
      data['affiliateId'] = groupId;
    }
    createArchiveSchedule(data)
    .then(result => {
      if (result.data.success) {
        loadProviderData();
        dispatch(loadIntegrationsThuunk());
        if (from === 'settings') {
          return;
        }
        setCreatedArchive(true);
      }
      setLoading(false);
    })
  };

  const handleContinue = () => {
    setCreatedArchive(false);
    setIntegration(null);
    navigation.goBack();
  };

  return (
    <>
      <View style={{ flex: 1 }}>
        {isCreatedArchive ? (
          <View style={styles.successContent}>
            <FontAwesome name="thumbs-o-up" size={60} />
            <Text style={[styles.text, styles.title]}>
              Your archive setup is complete! Click "Continue" to continue.
            </Text>
            <Text style={[styles.text, styles.comment]}>
              To manage your archive setup, go to your account settings after
              you are logged in.
            </Text>
            <CustomButton title="continue" onPress={handleContinue} />
          </View>
        ) : (
          <ScrollView>
            <IntegrationComponentFactory
              groupId={groupId}
              provider={provider}
              groupIntegration={groupIntegration}
              integration={integration}
            />
            {(provider?.name !== 'officearchive' && provider?.name !== 'email' && provider?.name !== 'filetransfer') && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginHorizontal: 20,
                }}>
                <CustomButton
                  title="Save"
                  width={'20%'}
                  onPress={handleSave}
                />
              </View>
            )}
          </ScrollView>
        )}
      </View>
      {loading && <Loader />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 767,
    padding: 30,
  },
  successContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topComment: {
    padding: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  setupPaneTitle: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 24,
    marginTop: 50,
  },
  title: {
    fontSize: 28,
    marginVertical: 25,
  },
  comment: {
    marginBottom: 20,
  },
});
