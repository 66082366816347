import React from "react";
import { providers } from "../../Screens/IntegrationProvider";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import End_Points from "../../Constants/Api";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ArchiveView from "../../Screens/Settings/Integrations/ArchiveView";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import { Icon } from "react-native-elements";

const ConnectArchiver = ({tenant, group}) => {
    const Tenant = tenant?.data?.tenant;
    const [archiver, setArchiver] = React.useState(null);
    const affiliate = useSelector((state) => state.User.profile)?.affiliate || group;
    const body = {action:'get_integrations'};
    let isTenant = false;

    if(Tenant?.id) {
        isTenant = true;
        body.tenant = Tenant.id
    } else if(affiliate?.id) body.group = affiliate.id;

    const {data} = getQuery(body)

    const renderArchivers = Object.keys(providers).map((key) => {
        const integration = providers[key];
        const handleClick = () => {
            setArchiver(integration)
        }
        const isActive = data?.integrations?.find((int) => int?.provider === integration.name)?.active;
        console.log(integration, data?.integrations, isActive)
        if(integration.type.name === 'archive' && integration.name !== 'email'){
            return(
                <View style={{backgroundColor:'#f1f1f1',justifyContent:'center',width:200,minHeight:175,alignItems:'center', borderRadius:10}}>
                <img onClick={handleClick} src={ integration.fullImageUrlPath} alt="box" imag style={{cursor:'pointer',width: 120,objectFit:'contain', height: 'auto'}}/>
                {isActive && 
                <Icon name="check" color="green" size={40} containerStyle={{position:'absolute',top:0,right:5}}/>
                }
                </View>
            )
        } else if(integration.type.name === 'archive' && integration.name === 'email'){
            return(
                <TouchableOpacity onPress={handleClick} style={{width:200,justifyContent:'center',alignItems:'center', cursor:'pointer'}}>
                <MaterialCommunityIcons name="email" size={24} color="black" />
                <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{integration.label}</Text>
                {isActive && 
                <Icon name="check" color="green" size={40} containerStyle={{position:'absolute',top:0,right:5}}/>
                }

                </TouchableOpacity>
            )
        }
    })

    return(
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginVertical:20}}>
                There are many ways to archive your MyRepChat data, and MyRepChat works with many popular archive providers, set up your data archiving in a way that meets your needs.
            </Text>
            {
             archiver ?
             <ArchiveView groupId={affiliate.id} provider={archiver} groupIntegration={null} Tenant={Tenant} isTenant={isTenant}/>
             : 
            <View style={{flexDirection:'row',flexWrap:'wrap',gap:50}}>
            {renderArchivers}
            </View>
            }

        </ScrollView>
    )
}


export default ConnectArchiver;