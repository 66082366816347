import React from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import Entypo from 'react-native-vector-icons/Entypo';
import AppColor from "../Constants/Color";
import { CommonStyle } from "../Screens/Chat/chat_side";
import AppFonts from "../Constants/Fonts";
import RequestMaker from "../Middleware/ApiCaller";
import { useToast } from "native-base";

const TranscriptView = ({isUser2, commId}) => {
    const Toast = useToast();
    const [text, setText] = React.useState('');
    const [cachedText, setCachedText] = React.useState('');
    const [loading, setLoading] = React.useState(false);


    const handleShowTranscript = async id => {

        if(text){
            setText('');
            return;
        }

        if(cachedText) {
            setText(cachedText);
            return;
        }

        const body = {
            action: 'get_transcript',
            communication: commId,
        };

        try{
            setLoading(true);
            let result = await RequestMaker(body);
            if (result.data.success) {
                const text = result?.data?.jobs[0]?.results?.transcripts[0]?.transcript
                setCachedText(text)
                setText(text)
            } else if(result.data.errorMessage){
                Toast.show({status:'error', title:result.data.errorMessage});
            }
            setLoading(false);
        } catch(err){
            Toast.show({
                status:'error',
                title: err.message
            })
        }

    }

    return(

        <TouchableOpacity onPress={() => {
            handleShowTranscript();
        }} activeOpacity={0.5} style={[isUser2 ? CommonStyle.left : CommonStyle.right]}>
            <View style={{flexDirection:'row',alignItems:'center'}}>
            <Entypo name='message' size={32} color={AppColor.PrimaryColor} />
                <Text style={{ marginLeft: 7, fontSize: 16, color: AppColor.PrimaryColor,fontFamily:AppFonts.Roboto_Regular }}>Transcript</Text>
                {   loading ?
                    <ActivityIndicator style={{marginLeft:'auto'}} color={AppColor.PrimaryColor} size={12}/> 
                    :
                    <Text style={{color:AppColor.PrimaryColor,fontFamily:AppFonts.Roboto_Regular,marginLeft:'auto'}}>{text ? 'hide' : 'show'}</Text>}
            </View>

            {
                text && 
                <Text style={CommonStyle.bubbleLeftText}>
                    {text}
                </Text>
            }
        </TouchableOpacity>

    )

}

export default TranscriptView;

