import React from "react";
import { Modal, ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import ComponentAccordion from "../../Components/ComponentAccordion";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import ModalWrapper from "../../Components/Modals/ModalWrapper";
import { useToast } from "native-base";

const AdministratorLogins = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [passwordModal, setPasswordModal] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');
    const Toast = useToast();
    const [focusedLogin, setFocusedLogin] = React.useState(null);

    const { refetch, data, isLoading, isError } = getQuery({
        type: "group",
        affiliate: affiliate.id,
        action: "get_logins"
    })

    if (isLoading) return <Loader backgroundColor={'transparent'} />

    if (!data) return null;

    const ACCOUNT_STATUS = {
        0: 'Inactive',
        1: 'Active',
        2: 'Temporary',
        3: 'Review'
    }

    const RenderComponent = ({ item }) => {
        const [data, setData] = React.useState({
            name: item?.name || '',
            email: item?.email || ''
        })

        const handleSave = async () => {
            const body = {
                action: 'create_login',
                affiliate: affiliate.id,
                type: 'group',
                ...data
            }
            if (item?.user_id) {
                body.action = 'update_login';
                body.id = item.user_id;
            }
            const result = await RequestMaker(body);
            if (result.data.success) {
                refetch();
            }
        }

        const handleRemove = async () => {
            const body = {
                action: 'delete_login',
                affiliate: affiliate.id,
                type: 'group',
                id: item.user_id
            }
            const result = await RequestMaker(body);
            if (result.data.success) {
                refetch();
            }
        }

        const handleResetMFA = async () => {
            const body = {
                action: 'reset_mfa',
                userId: item.user_id
            }
            const result = await RequestMaker(body);
            if (result.data.success) {
                refetch();
            }
        }

        const handleApprove = async () => {
            const body = {
                action: 'update_login',
                affiliate: affiliate.id,
                type: 'group',
                status: 1,
                id: item.user_id,
                name: item.name,
                email: item.email
            }
            const result = await RequestMaker(body);
            if (result.data.success) {
                refetch();
            }
        }

        const handlePassword = () => {
            setPasswordModal(true);
            setFocusedLogin(item);
        }

        return (
            <>
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>Name</Text>
                <CustomInput onChangeText={e => setData({ ...data, name: e })} value={data.name} />
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>Email</Text>
                <CustomInput disabled={item?.user_id} onChangeText={e => setData({ ...data, email: e })} value={data.email} />
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 20 }}>
                    {item?.user_id &&
                        <CustomButton onPress={handleRemove} width={'15%'} outline title={'Remove'} />
                    }
                    {item?.user_id &&
                        <CustomButton width={'15%'} onPress={handleResetMFA} outline title={'Reset MFA'} />
                    }
                    {item?.user_id &&
                        <CustomButton onPress={handlePassword} width={'25%'} outline title={'Change Password'} />
                    }
                    {(item?.user_id && item?.status !== 1) &&
                        <CustomButton onPress={handleApprove} width={'15%'} outline title={'Approve'} />
                    }
                    <CustomButton onPress={handleSave} width={'15%'} title={'Save'} />
                </View>
            </>
        )
    }


    const handleChangePassword = async () => {
        if (password !== passwordConfirm) {
            Toast.show({
                title: "Passwords must match",
                status: 'error'
            })
            return;
        }
        try {
            const body = {
                password: password,
                username: focusedLogin.email,
                userId: focusedLogin.user_id,
                sendEmail: false,
                action: "reset_password"
            }
            const result = await RequestMaker(body);
            if (result.data.success) {
                Toast.show({
                    title: "Password changed successfully",
                    status: 'success'
                })
                setPasswordModal(false);
                refetch();
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
        } catch(err){
            Toast.show({
                title: err.message,
                status: 'error'
            })
        } 
    }


    return (
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 10 }}>
                Manage administrator logins for this group. Use the Add button to open the settings panel and provide a name and an email address and click on save to create a group administrator login. If this email address is not currently being used, a generated password will be emailed to the email address, the user can change their password after they login.
                {'\n'} {'\n'}
                To remove an existing group login just select the login row to display the setting and click the remove button.
            </Text>
            <ComponentAccordion
                columns={['Name', 'Email', 'Status']}
                data={data.logins}
                renderListItem={item => [item.name, item.email, ACCOUNT_STATUS[item.status]]}
                renderComponent={(item) => <RenderComponent item={item} />}
                allowAdd
            />
            {passwordModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={passwordModal} transparent={true}>
                    <ModalWrapper>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Change Password</Text>
                        <CustomInput secure onChangeText={val => setPassword(val)} titleStyle={{ marginTop: 10 }} title={'New Password'} />
                        <CustomInput secure onChangeText={val => setPasswordConfirm(val)} titleStyle={{ marginTop: 10 }} title={'Confirm Password'} />
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 20 }}>
                            <CustomButton title={'Cancel'} outline onPress={() => setPasswordModal(false)} width={'40%'} />
                            <CustomButton title={'Save'} onPress={handleChangePassword} width={'40%'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }

        </ScrollView>
    )
}

export default AdministratorLogins;