/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect, useState } from 'react';
import {
  StatusBar,
  BackHandler,
  Text,
  LogBox,
  Modal,
  View,
} from 'react-native';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { NativeBaseProvider, extendTheme } from 'native-base';
import Alert, { AlertRoot } from 'Alert';
import * as Font from 'expo-font';
import { useExternalScript } from './Source/Middleware/useExternalScript';
// firebase setup
import firebase from './firebase_config';
import storeData from './Source/AsyncUtils/StoreData';
import 'file-loader?name=firebase-messaging-sw.js!./firebase-message-sw.js';
// import firebase from 'firebase/app';
import 'firebase/messaging';
import Router from './Source/Router';
import store from './Source/Redux';
import getData from './Source/AsyncUtils/GetData';
import CustomButton from './Source/Components/Button';
import { setToken } from './Source/Redux/index';
import { loadRecentMessagesThunk } from './Source/Redux/recent_message';
import { setReceivedMessageThunk } from './Source/Redux/recevied_message';
import {
  setAccountInactivityThunk,
  setCountTimerThunk,
  setTimeOutThunk,
} from './Source/Redux/account_active';
import './customDrawerStyles.css';
import { useGoogleAnalytics } from './Source/Middleware/useGoogleAnalytics';
import useGoogleTagManager from './Source/Middleware/useGoogleTagManager';

let analytics;

if(firebase.analytics.isSupported()){
  analytics = firebase.analytics();
}

export { analytics }


if (firebase.messaging.isSupported()){
  const messaging = firebase.messaging();

  messaging
    .requestPermission()
    .then(() => {
      return messaging.getToken();
    })
    .then(async (token) => {
      await storeData('fcmToken', token);
      console.log('token...', token);
    })
    .catch((err) => console.log(err));

  messaging
    .getToken()
    .then(async (currentToken) => {
      if (currentToken) {
        await storeData('fcmToken', currentToken);
        console.log('FCM token> ', currentToken);
      } else {
        console.log('No Token available');
      }
    })
    .catch((error) => {
      console.log('An error ocurred while retrieving token. ', error);
    });

  messaging.onMessage((payload) => {
    console.log('Message received. ', payload);

    const { title, ...options } = payload.notification;

    if(analytics){
      analytics.logEvent('message_received');
    }

    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(function (err) {
        console.log('Service worker registration failed, error:', err);
      });
    
    showNotification(payload);
  });

  function showNotification(payload) {
    Notification.requestPermission(function (result) {
      if (result === 'granted') {
        store.dispatch(loadRecentMessagesThunk());
        store.dispatch(setReceivedMessageThunk(payload));

        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification(payload.notification.title, {
            body:
              payload.data.contactName + ' for ' + payload.data.accountContact,
            tag: payload.notification.tag,
            icon: require('./Source/Assets/Images/splashlogo.png'),
            silent:false
          });
        });
      }
    });
  }
}

const custom_fonts = {
  'Roboto-Regular': require('./Source/Assets/fonts/Poppins-Regular.ttf'),
  'Roboto-Bold': require('./Source/Assets/fonts/Poppins-Bold.ttf'),
};

export default function App() {
  const [authenticated, setAuthenticate] = useState(false);
  const [inactivity, setInactivity] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [visible, setVisible] = useState(false);
  
  const GTM = useGoogleTagManager('GTM-5J7MKG4G');

  const externalScript = "https://wsmcdn.audioeye.com/aem.js";
  const state = useExternalScript(externalScript);
  
  const GALink = 'https://www.googletagmanager.com/gtag/js?id=G-ZFZTQC1E61';
  const GA = useGoogleAnalytics(GALink);
  
  store.subscribe(() => {
    setAuthenticate(store.getState().User.authenticated);
    setInactivity(store.getState().accountActivity.inactivity);
    setIsTimedOut(store.getState().accountActivity.timeOut);
  });


  const deleteToken = async () => {
    const token = await getData('token');
    if (token) {
      localStorage.removeItem('token');
    }
  }

  useEffect(() => {
    let isSubscribed = true
    loadFonts();

    deleteToken()

    return () => (isSubscribed = false)

  }, []);

  useEffect(() => {
    if (inactivity && isTimedOut) {
      store.dispatch(setCountTimerThunk(false));
      return setVisible(true);
    }
    setVisible(false);
  }, [inactivity, isTimedOut]);


  const loadFonts = async () => {
    await Font.loadAsync(custom_fonts);
  };

  const handleSubmit = () => {
    setVisible(false);
    store.dispatch(setCountTimerThunk(true));
    store.dispatch(setAccountInactivityThunk(false));
    store.dispatch(setTimeOutThunk(false));
    store.dispatch(setToken({ token: null, authenticated: false }));
  };

  const ContactAdminModal = (props) => {
    return (
      <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        backdropOpacity={0.9}
        visible={visible}
        transparent={true}
        onDismiss={() => {
          handleSubmit();
        }}
        onBackdropPress={() => handleSubmit()}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          border: 'none',
        }}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#0007',
          }}>
          <View
            style={{
              backgroundColor: 'white',
              padding: 10,
              width: '90%',
              maxWidth: 400,
            }}>
            <Text style={{ textAlign: 'center', fontSize: 18 }}>Message</Text>
            <View
              style={{
                height: 1,
                backgroundColor: '#ffffff',
                marginVertical: 10,
              }}
            />
            <Text style={{ color: '#000000', textAlign: 'center' }}>
              Your account will be logged out successfully
            </Text>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 10,
              }}>
              <CustomButton
                title={'OK'}
                style={{ width: '40%' }}
                onPress={() => handleSubmit()}
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  };


  return (
    <>
      <NativeBaseProvider>
        <Provider store={store}>
          <Router />
        </Provider>
      </NativeBaseProvider>
      <AlertRoot />
      {visible && <ContactAdminModal />}
    </>
  );
}
